export default [
  {
    type: 'h1',
    text: 'Medium Apartment - Ein Traum in grün',
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Lassen Sie sich von diesem grünen Traum verzaubern und verwandeln Sie dieses Apartment in ein einziges Highlight.',
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Unsere Besichtigung beginnt in diesem schier endlos erscheinenden Eingangsbereich, der über eine Niesche verfügt, in der sich problemlos eine Garderobe mit Sitzmöglichkeiten einrichten lässt.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/medium-apartment/Eingangsbereich.jpg',
      'img/interior/medium-apartment/Niesche.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Vom Eingangsbereich aus gelangen Sie in den riesigen Wohnbereich, der sich ganz nach Belieben gestalten lässt. Ob Fernsehbereich oder Leseecke - beides lässt sich mit Leichtigkeit in dieser Wohnlandschaft unterbringen.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/medium-apartment/EingangWohnbereich.jpg',
      'img/interior/medium-apartment/Wohnbereich.jpg',
      'img/interior/medium-apartment/Wohnbereich1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Die gemütliche offene Küche mit Theke lässt Sie Ihre Kochkünste weiter ausbauen. Durch den offenen Schnitt Ihres Kochparadieses lassen sich Gespräche kinderleicht mit Freunden, die im anschließenden Essbereich sitzen, führen. Schaffen Sie sich einen Essbereich, in dem Sie zu jeder Zeit gutes Essen genießen können.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/medium-apartment/Kueche.jpg',
      'img/interior/medium-apartment/Kueche1.jpg',
      'img/interior/medium-apartment/Essbereich.jpg',
    ],
    customCss: '',
  },

  {
    type: 'section',
    text:
      'Das gemütliche Schlafzimmer mit angrenzendem Badezimmer setzt Ihrer Kreativität keine Grenzen. Mit wenigen Möbeln lässt sich hier eine Ruheoase für ruhsame Nächte gestalten.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/medium-apartment/Schlafzimmer.jpg',
      'img/interior/medium-apartment/Schlafzimmer1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Dieses schnuckelige Badezimmer mit Dusche befriedigt all Ihre Bedürfnisse. In der geräumigen Dusche ist Platz für zwei Personen.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/medium-apartment/Badezimmer.jpg',
      'img/interior/medium-apartment/Badezimmer1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Damit sind wir am Ende der Besichtung. Dieser Traum in grün lässt Raum für Ihre Ideen, werden Sie kreativ und geben Sie diesem Apartment Ihre eigene persönliche Note.',
    customCss: '',
  },
];
