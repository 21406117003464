export default [
  {
    type: 'h1',
    text: 'Luxury House - Mediteranes Luxushaus',
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Willkommen in Ihrem mediteranen Luxus-Zuhause, schon der Eingangsbereich gibt Ihnen das wohlige Gefühl von Geborgenheit und Entspannung.',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/luxury-houses/Eingangsbereich.jpg'],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Begrüßen Sie Ihre Gäste in diesem großzügigen Eingangsbereich, richten Sie eine Garderobe ein und schaffen Sie eine Möglichkeit, sich mit Leichtigkeit die Schuhe auszuziehen.',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/luxury-houses/Eingangsbereich1.jpg'],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Die offene Küche mit hochwertigen Küchengeräten lädt zum gemeinsamen Kochen mit Freunden ein. Die freistehende Kücheninsel lässt sich gut als Frühstücksplatz oder für einen Plausch mit Freunden nutzen.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/luxury-houses/Kueche.jpg',
      'img/interior/luxury-houses/Kueche1.jpg',
      'img/interior/luxury-houses/Kueche2.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Schaffen Sie einen Essbereich, in dem Sie zu jeder Zeit gutes Essen genießen können.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/luxury-houses/Essbereich.jpg',
      'img/interior/luxury-houses/Essbereich1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Dieser geräumige Wohnbereich lässt sich in einen Ort der Erholung verwandeln. Der Kamin lädt dazu ein, eine gemütliche Sitzecke einzurichten, auch für einen Platz zum Lesen ist genügend Fläche vorhanden.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/luxury-houses/Wohnbereich.jpg',
      'img/interior/luxury-houses/Wohnbereich1.jpg',
      'img/interior/luxury-houses/Wohnbereich2.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Folgen Sie der Treppe ins Obergeschoss, in dem 3 Schlafzimmer und ein Badezimmer auf Sie warten.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/luxury-houses/Treppenhaus.jpg',
      'img/interior/luxury-houses/Obergeschoss.jpg',
      'img/interior/luxury-houses/Obergeschoss1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Das Hauptschlafzimmer ist mit einem wunderschönen Mosaikfenster ausgestattet. Zudem bietet das Zimmer genügend Raum, um sowohl ein großes gemütliches Bett als auch eine Schminkecke für die Dame des Hauses einzurichten. Ein zustäzliches Highlight ist der anschließende begehbare Kleiderschrank mit viel Stauraum.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/luxury-houses/Schlafzimmer.jpg',
      'img/interior/luxury-houses/Schlafzimmer1.jpg',
      'img/interior/luxury-houses/Kleiderschrank.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Das in lila gehaltene Schlafzimmer lässt sich in ein kleines Paradis für Ihre Gäste verwandeln, lassen Sie Ihrer Fantasie freien lauf.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/luxury-houses/Schlafzimmer2.jpg',
      'img/interior/luxury-houses/Schlafzimmer3.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Dieses in rot und schwarz gestaltete Schlafzimmer lässt sich ebensogut in ein Fernsehzimmer umfunktionieren. Mit dem richtigen Mobiliar zaubern Sie hier echtes Kino-Flair.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/luxury-houses/Schlafzimmer4.jpg',
      'img/interior/luxury-houses/Schlafzimmer5.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Das längliche Bad ist durch sein großes Fenster lichtdurchflutet, zudem sind die Sanitäreinrichtungen auf dem neusten Stand.',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/luxury-houses/Badezimmer.jpg'],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Damit sind wir am Ende der Besichtung. Die mediterane Architektur hat natürlich ihren Preis. Dieser lohnt sich aber vollkommen, denn ihr Traum kann hier auf zwei Etagen wahr werden. Ihnen steht nicht nur eine grosse Fläche zur Verfügung, sondern durch die gute Raumaufteilung des Hauses auch viele, viele Möglichkeiten für Ihre Inneneinrichtung.',
    customCss: '',
  },
];
